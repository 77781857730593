const initDocRemovalModal = () => {
  const destroyDocumentFormModal = document.getElementById('destroy-document-form-modal');
  if (!destroyDocumentFormModal) return;
  destroyDocumentFormModal.addEventListener('show.bs.modal', (event) => {
    const el = event.currentTarget;

    el.querySelector(".modal-body").innerHTML = event.relatedTarget.dataset.modalContent;
    el.querySelector(".modal-footer > .confirm-destroy").href = event.relatedTarget.dataset.href;
  });
}
document.addEventListener("turbolinks:load", () => {
  initDocRemovalModal();
});