import { Datepicker } from 'vanillajs-datepicker';
import fr from "vanillajs-datepicker/locales/fr";
import en from 'vanillajs-datepicker/locales/en-CA';
import { format, parse,  addBusinessDays } from 'date-fns';
import { currentLocale, handleInputRowRemove, removeLoader, renderLoader } from '../../common';
import { fetchCourseInstructors } from '../../actions/qualifications';
import { fetchCourseDetails } from "../../actions/fts_courses";
import { fetchCustomerContacts } from '../../actions/customer_contacts';

Object.assign(Datepicker.locales, fr, en);
let dp;

const updateCourseList = () => {
  try {
    const approval = document.getElementById("approval_type").value;
    const el = document.getElementById("course_booking_fts_course_id");
    el.setAttribute("disabled", true);
    el.innerHTML = "<option>Please wait. Loading courses...</option>";

    fetch(`/fts_courses?approval=${approval}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).then((response) => response.json())
      .then(result => {
      el.innerHTML = "";
      result.forEach(({id, full_name}) => {
        el.innerHTML += `
          <option value='${id}'>
            ${full_name}
          </option>
        `;
      });
      el.removeAttribute("disabled");
      el.dispatchEvent(new Event("change"));
    });
  } catch (err) {
    console.error("Error fetching courses based on approval type:", err);
  }
}

const updateCourseDetails = () => {
  try {
    const course = document.getElementById("course_booking_fts_course_id").value;
    if (isNaN(course)) return;
    const approvalNumberEl = document.getElementById("approval_number");
    const courseDurationEl = document.getElementById("course_booking_duration_in_days")

    renderLoader(approvalNumberEl, "secondary", true);

    fetchCourseDetails(course)
      .then(result => {
        approvalNumberEl.innerHTML = result.approval_number;
        courseDurationEl.value = result.duration_in_days;
        calculateCourseEndDate();
        updateCourseInstructorLists();
      });

  } catch (err) {
    console.error("Error fetching approval number for course:", err);
  }
}

const updateCustomerContacts = () => {
  const customerEl = document.getElementById("course_booking_host_id");
  if (!customerEl) return;

  const customerId = parseInt(customerEl.value, 10);
  if (isNaN(customerId)) return;
  if (customerId <= 0) return;

  const selectEl = document.getElementById("course_booking_course_booking_customers_attributes_0_customer_contact_id");
  if (!selectEl) return;

  selectEl.setAttribute("disabled", true);
  selectEl.innerHTML = "<option>Please wait. Loading customer's contacts...</option>";

  fetchCustomerContacts(customerId).then(results => {
    if (results.length) {
      const selectOptions = results.reduce((options, contact) => options + `<option value='${contact.id}'>${contact.full_name}</option>`, "");
      selectEl.innerHTML = selectOptions;
    } else {
      selectEl.innerHTML = "<option>No contacts found for this customer.</option>"
    }

    selectEl.removeAttribute("disabled");

  });
}

const updateCustomerLocations = () => {
  const customerEl = document.getElementById("course_booking_host_id");
  if (!customerEl) return;

  const customerId = parseInt(customerEl.value, 10);
  if (isNaN(customerId)) return;
  if (customerId <= 0) return;

  const selectEl = document.getElementById("course_booking_customer_location_id");
  if (!selectEl) return;

  selectEl.setAttribute("disabled", true);
  selectEl.innerHTML = "<option>Please wait. Loading customer's locations...</option>";

  fetch(`/customers/${customerId}/customer_locations`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  }).then(response => response.json())
    .then(results => {
      selectEl.innerHTML = "";
      if (results.length) {
        results.forEach(result => {
          selectEl.innerHTML += `<option value="${result.id}">${result.full_address}</option>`;
        });
      } else {
        selectEl.innerHTML = "<option>Customer does not have any locations saved in gateway</option>"
      }
      selectEl.removeAttribute("disabled");
    })

}

const getDataOptions = (section) =>
  document.getElementById("course-instructors").getAttribute(`data-${section}-options`) ||
  "<option>No instructors available.</option>"

const updateCourseInstructorList = (event) => {
  const instructorSelect = event.target.closest(".instructor-container").querySelector(".instructor-select.form-select");
  if( !instructorSelect) return;

  const options = getDataOptions(event.target.value);
  instructorSelect.innerHTML = options;
}

const updateCourseInstructorLists = () => {
  const courseSelect = document.getElementById("course_booking_fts_course_id");
  if (!courseSelect) return;

  const courseId = courseSelect.value
  if (isNaN(courseId)) return;

  const courseInstructorsContainer = document.getElementById("course-instructors");
  renderLoader(courseInstructorsContainer);

  fetchCourseInstructors(courseId)
    .then(results => {

      const sections = results.reduce((pv, cv) => {
        if (cv.capability === "both") {
          ["frontend", "backend"].forEach((section) => {
            if (!pv[section] || !pv[section].find((instructor) => instructor.instructorId === cv.user.id)) {
              pv[section] = pv[section] || [];
              pv[section].push({
                instructorId: cv.user.id,
                name: cv.user.full_name
              });
            }
          });
        }

        pv[cv.capability] = pv[cv.capability] || [];
        pv[cv.capability].push({
          instructorId: cv.user.id,
          name: cv.user.full_name
        });

        return pv;
      }, {});

      // check if anyone has been added to frontend and backend
      if (sections["frontend"] && sections["backend"]) {
        sections["frontend"].forEach(frontendInstructor => {
          if (sections["backend"].find(backendInstructor => backendInstructor.instructorId === frontendInstructor.instructorId) &&
            !(!!sections["both"] || sections["both"].find(multiInstructor => multiInstructor.instructorId === frontendInstructor.instructorId))
          ) {
            sections["both"] = sections["both"] || [];
            sections["both"].push(frontendInstructor);
          }
        });
      }

      const noInstructors = { id: 0, name: document.getElementById("course-instructors")?.dataset.noInstructors };

      if (!sections["frontend"]) sections["frontend"] = [noInstructors];
      if (!sections["backend"]) sections["backend"] = [noInstructors];
      if (!sections["both"]) sections["both"] = [noInstructors];

      Object.keys(sections).forEach((section) => {
        courseInstructorsContainer.setAttribute(`data-${section}-options`, sections[section].reduce((ps, cs) => {
          ps += `<option value=${cs.instructorId}>${cs.name}</option>`;
          return ps;
        }, ""));
      });

      courseInstructorsContainer.querySelectorAll(".instructor-container").forEach(instructorSection => {
        const sectionValue = instructorSection.querySelector(".section-select.form-select").value;
        const options = courseInstructorsContainer.getAttribute(`data-${sectionValue}-options`);
        instructorSection.querySelector(".instructor-select.form-select").innerHTML = options;
      });

      removeLoader(courseInstructorsContainer);
    });
}

const calculateCourseEndDate = () => {
  if (!dp) {
    console.warn("Datepicker not found! Will not be able to parse end date");
    return;
  }
  const startDate = document.getElementById("course_booking_start_date");
  if (!startDate) return;
  if (!startDate.value) return;
  const durationEl = document.getElementById("course_booking_duration_in_days");
  if (!durationEl) return;

  const duration = Math.ceil((parseInt(document.getElementById('course_booking_duration_in_days').value, 10) || 0) - 1);
  if (duration < 0) return;

  const dateFormat = dp.config.format.replace(/m/g, 'M');

  const parsedStartDate = parse(startDate.value, dateFormat, new Date());
  const parsedEndDate = addBusinessDays(parsedStartDate, duration);
  const endDate = document.getElementById("end_date");
  if (!isNaN(parsedEndDate) && duration >= 0)
    endDate.innerHTML = format(parsedEndDate, dateFormat);
  else
    endDate.innerHTML = ""
}

const handleApprovalTypeChange = () => {
  const approval = document.getElementById("approval_type");
  if (!approval) return;
  updateCourseList();
  approval.addEventListener("change", () => updateCourseList());
}

const handleCourseSelectionChange = () => {
  const course = document.getElementById("course_booking_fts_course_id");
  if (!course) return;
  updateCourseDetails();
  course.addEventListener("change", updateCourseDetails);
}

const handleStartDateChange = () => {
  const startDate = document.querySelector("input#course_booking_start_date");
  if (!startDate) return;
  calculateCourseEndDate();
  startDate.addEventListener("change", () => calculateCourseEndDate());
  startDate.addEventListener("changeDate", calculateCourseEndDate);
}

const handleCustomerChange = () => {
  const customerEl = document.getElementById("course_booking_host_id");
  if (!customerEl) return;
  updateCustomerLocations();
  updateCustomerContacts();
  customerEl.addEventListener("change", () => {
    updateCustomerLocations();
    updateCustomerContacts();
  });
}


const addDatepickerToForm = () => {
  const startDate = document.querySelector("input#course_booking_start_date");
  if (!startDate) return;

  let language = "en";
  if (currentLocale() == "en") {
    language = "en-CA"
  } else {
    language = currentLocale();
  }

  dp = new Datepicker(startDate, {
    buttonClass: 'btn',
    language
  });
}

const handleConfigureDuration = (elementId, func) => {
  const el = document.getElementById(elementId);
  if (!el) return;
  const durationEl = document.getElementById("course_booking_duration_in_days")
  if (!durationEl) return;
  el.addEventListener("click", () => {
    func(durationEl);
    calculateCourseEndDate();
  })
};

let curInstructorContainerCount = 0;

const handleAddInstructor = () => {
  const targetContainer = document.getElementById("course-instructors")
  if (!targetContainer) return;

  const observer = new MutationObserver((mutations, _observer) => {
    for (const mutation of mutations) {
      if (mutation.type === "childList") {
        const instructorContainers = targetContainer.querySelectorAll(":scope > .instructor-container");
        if (instructorContainers.length > 1) {
          const instrCount = instructorContainers.length;

          targetContainer.querySelectorAll(".remove-course-booking-instructor").forEach(removeAction => {
            removeAction.classList.remove("visually-hidden");
          });

          targetContainer.querySelectorAll(".section-select-container .section-select.form-select").forEach((sectionSelect, index) => {
            sectionSelect.removeAttribute("disabled");

            if (instrCount === 2 && curInstructorContainerCount === 1 && index === 0) {
              sectionSelect.value = "frontend";
              sectionSelect.dispatchEvent(new Event("change"));
            } else if (instrCount === 2 && curInstructorContainerCount === 1 && index === 1) {
              sectionSelect.value = "backend";
            }
          });

          instructorContainers[instrCount-1].querySelector(".section-select.form-select").addEventListener("change", updateCourseInstructorList);
          instructorContainers[instrCount-1].querySelector(".section-select.form-select").dispatchEvent(new Event("change"));
          curInstructorContainerCount = instrCount;
        } else {
          targetContainer.querySelectorAll(".remove-course-booking-instructor").forEach(removeAction => {
            removeAction.classList.add("visually-hidden");
          });

          targetContainer.querySelectorAll(".section-select-container .section-select.form-select").forEach(sectionSelect => {
            const prevValue = sectionSelect.value;
            sectionSelect.value = "both"
            sectionSelect.setAttribute("disabled", true);
            if (prevValue !== sectionSelect.value) sectionSelect.dispatchEvent(new Event("change"));
          });
        }
      }
    }
  });

  observer.observe(targetContainer, { childList: true });
}

const handleInstructorSectionChange = () => {
  const sections = document.querySelectorAll("#course-instructors > .instructor-container .section-select.form-select");
  if (!sections.length) return;

  sections.forEach(section => {
    section.addEventListener("change", updateCourseInstructorList);
  });
}

const handleRemoveButtons = () => {
  document.querySelectorAll(".remove-course-booking-instructor.remove-row-button").forEach(removeButton => {
    removeButton.addEventListener("click", ({target}) => {
      handleInputRowRemove(target, target.closest(".instructor-container"));
    });
  });
}

document.addEventListener("turbolinks:load", () => {
  curInstructorContainerCount = document.querySelectorAll("#course-instructors > .instructor-container").length
  handleApprovalTypeChange();
  handleCourseSelectionChange();
  handleInstructorSectionChange();
  addDatepickerToForm();
  handleStartDateChange();
  handleCustomerChange();
  handleConfigureDuration('add-day', (durationEl) => { durationEl.value = (parseInt(durationEl.value, 10) || 0) + 1 });
  handleConfigureDuration('subtract-day', (durationEl) => { durationEl.value = (parseInt(durationEl.value, 10) || 0) - 1});
  handleAddInstructor();
  handleRemoveButtons();
})
