import { fetchCustomerContacts } from "../../actions/customer_contacts";

const updateCustomerContacts = () => {
  const customerEl = document.getElementById("course_booking_customer_customer_id");
  if (!customerEl) return;

  const customerId = parseInt(customerEl.value, 10);
  if (isNaN(customerId)) return;
  if (customerId <= 0) return;

  const selectEl = document.getElementById("course_booking_customer_customer_contact_id");
  if (!selectEl) return;

  selectEl.setAttribute("disabled", true);
  selectEl.innerHTML = `<option>${selectEl.dataset.loading}</option>`;

  fetchCustomerContacts(customerId).then(results => {
    if (results.length) {
      const selectOptions = results.reduce((options, contact) => options + `<option value='${contact.id}'>${contact.full_name}</option>`, "");
      selectEl.innerHTML = selectOptions;
    } else {
      selectEl.innerHTML = `<option>${selectEl.dataset.none}<option>`;
    }

    selectEl.removeAttribute("disabled");
  });
}

const handleCustomerChange = () => {
  const customerEl = document.getElementById("course_booking_customer_customer_id");
  if (!customerEl) return;

  updateCustomerContacts();

  customerEl.addEventListener("change", updateCustomerContacts);
};

document.addEventListener("turbolinks:load", () => {
  handleCustomerChange();
});
