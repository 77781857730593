export const fetchCourseDetails = async (ftsCourseId) => {
  try {
    const response = await fetch(`/fts_courses/${ftsCourseId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    });

    return await response.json();
  } catch (error) {
    console.error(`Error occurred fetching course details for course id: ${ftsCourseId}`, error);
  }
}
