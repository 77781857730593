import { addFieldButtonClickListener, configureAddFieldButtons, handleInputRowRemove } from "../../common";

const shipperChanged = ({ target: shipperEl }) => {
  const accountNumber =  document.getElementById("shipment_account_number");
  const otherField = document.getElementById("shipment_shipper_other");
  if (shipperEl.value === "3") {
    accountNumber.removeAttribute("disabled");
    otherField.classList.remove("visually-hidden");
    otherField.removeAttribute("disabled");
  } else {
    accountNumber.setAttribute("disabled", true);
    otherField.classList.add("visually-hidden");
    otherField.setAttribute("disabled", true);
  }
};

const handleShipperChange = () => {
  const shipperEl = document.getElementById("shipment_shipper");
  if (!Boolean(shipperEl)) return;
  shipperEl.addEventListener("change", shipperChanged);
};

const handlePackageAdds = () => {
  const target = document.getElementById("packages");
  if (!target) return;
  var observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      const isChildList = mutation.type === "childList";

      if (isChildList) {
        document.querySelectorAll('.add-fields').forEach( element => {
          element.removeEventListener("click", addFieldButtonClickListener);
        });
        configureAddFieldButtons();
      }
    });
  });

  observer.observe(target, { childList: true });
}

const handleRemoveButtons = () => {
  document.querySelectorAll(".remove-package-item.remove-row-button").forEach(removeButton => {
    removeButton.addEventListener("click", ({target}) => {
      handleInputRowRemove(target, target.closest(".package-item"));
    });
  });

  document.querySelectorAll(".remove-package.remove-row-button").forEach(removeButton => {
    removeButton.addEventListener("click", ({target}) => {
      handleInputRowRemove(target, target.closest(".package"));
    })
  });
}

document.addEventListener("turbolinks:load", () => {
  handleShipperChange();
  handlePackageAdds();
  handleRemoveButtons();
});
