export const fetchCourseInstructors = async (ftsCourseId) => {
  const response = await fetch(`/fts_courses/${ftsCourseId}/qualified_instructors`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  });

  return await response.json();
};
