import { customPopover } from '../../common'
import {handleSectionChange} from "../course_booking_instructors/new";
const handleRemoveExpenseModal = () => {
  const removeExpenseModal = document.getElementById("remove-expense-modal");
  if (!removeExpenseModal) return;
  removeExpenseModal.addEventListener("show.bs.modal", (e) => {
    const triggerEl = e.relatedTarget;
    const tableRow = triggerEl.closest("tr");

    const expenseName = tableRow.querySelector("td.expense-name").innerText;
    const modalBodyExpenseNameEl = removeExpenseModal.querySelector(".modal-body > .expense-name");
    modalBodyExpenseNameEl.innerHTML = expenseName;

    const modalFooterConfirmLink = removeExpenseModal.querySelector(".modal-footer > a.remove-expense-path")
    modalFooterConfirmLink.href = `/expenses/${tableRow.dataset.id}`;
  });
};

const handleRemoveShipmentModal = () => {
  const removeShipmentModal = document.getElementById("remove-shipment-modal");
  if (!removeShipmentModal) return;
  removeShipmentModal.addEventListener("show.bs.modal", (e) => {
    const triggerEl = e.relatedTarget;
    const tableRow = triggerEl.closest("tr");

    const modalFooterConfirmLink = removeShipmentModal.querySelector(".modal-footer > a.remove-shipment-path")
    modalFooterConfirmLink.href = `/shipments/${tableRow.dataset.id}`;
  });
};

const handleChangeInstructorModalClose = () => {
  const modal = document.getElementById("change_instructor_form");

  if (!modal) return;
  modal.addEventListener("hidden.bs.modal", () => {
    modal.querySelector(".modal-body").innerHTML = "<div class='spinner-border' role='status'><span class='visually-hidden'>Loading...</span></div>";
  });
};

const handleChangeInstructorModalOpen = () => {
  const modal = document.getElementById("change_instructor_form");

  if (!modal) return;

  modal.addEventListener("shown.bs.modal", () => {
    const observer = new MutationObserver((mutations, mutationInstance) => {
      if (document.getElementById("course_booking_instructor_section")) {
        handleSectionChange();
        mutationInstance.disconnect();
      }
    });

    observer.observe(document, { childList: true, subtree: true });
  });
};

document.addEventListener("turbolinks:load", () => {
  handleRemoveExpenseModal();
  handleRemoveShipmentModal();
  handleChangeInstructorModalClose();
  handleChangeInstructorModalOpen();
  document.querySelectorAll("[data-my-toggle='customPopover']").forEach(el => {
    customPopover(el);
  });
});
