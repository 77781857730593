import "./new";
import "./show";

const getDocuments = async (id) => {
  const response = await fetch(
    `/course_bookings/${id}/documents/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
  );

  return await response.json();
}

const handleDocumentsModal = () => {
  const modal = document.getElementById("documents-modal");
  if (!modal) return;

  const modalHeader = modal.querySelector(".modal-header");
  const modalBody = modal.querySelector(".modal-body");
  const modalFooter = modal.querySelector(".modal-footer");

  modal.addEventListener("show.bs.modal", async (e) => {
    const triggerEl = e.relatedTarget;
    const tableRow = triggerEl.closest("tr");

    modalHeader.innerText = `${tableRow.dataset.courseBookingIdentifier} · ${modalHeader.dataset.originalHeader}`;

    const id = tableRow.dataset.courseBookingId;
    const documents = await getDocuments(id);

    modalBody.innerHTML = "";
    const table = document.createElement("table");
    table.classList.add("table");
    table.classList.add("table-hover");
    modalBody.appendChild(table);

    const tableHeader = document.createElement("thead");
    const tableHeaderRow = document.createElement("tr");

    const { tableHeaderId, tableHeaderName, tableHeaderType, tableHeaderCreatedAt, tableHeaderUpdatedAt } = modal.dataset;
    tableHeaderRow.innerHTML = `
      <th>${tableHeaderId}</th>
      <th>${tableHeaderName}</th>
      <th>${tableHeaderType}</th>
      <th>${tableHeaderCreatedAt}</th>
      <th>${tableHeaderUpdatedAt}</th>
      <th></th>
    `;
    tableHeader.appendChild(tableHeaderRow);
    table.appendChild(tableHeader);

    const tableBody = document.createElement("tbody");
    table.appendChild(tableBody);

    for (let doc of documents) {
      const row = document.createElement("tr");
      row.innerHTML = `
        <td>${doc.id}</td>
        <td>${doc.filename}</td>
        <td>${doc.file_extension}</td>
        <td>${doc.created_at_full}</td>
        <td>${doc.updated_at_full}</td>
        <td class="d-flex gap-2">
          <a href='/uploads/documents/${doc.id}' target="_blank" rel="noopener noreferrer">
            <i class="fa-duotone fa-2xl fa-download"></i>
          </a>
          <a href='/documents/${doc.id}' data-confirm='Remove document: ${doc.filename}. Are you sure?' rel='nofollow' data-method='delete'>
            <i class="fa-solid fa-2xl fa-trash-alt"></i>
          </a>
        </td
      `

      tableBody.appendChild(row);
    }
    modalFooter.querySelector("#modal-upload-document-btn").setAttribute("href", `/course_bookings/${id}/documents/new`);
  });
};

const handleYearChanged = () => {
  const yearSelector = document.querySelector("select#view_year");
  if (!yearSelector) return;

  yearSelector.addEventListener("change", (e) => {
    e.currentTarget.closest('form').submit();
  });
}

document.addEventListener("turbolinks:load", () => {
  handleDocumentsModal();
  handleYearChanged();
});
