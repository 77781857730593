export const fetchCustomerContacts = async (customerId) => {
  try {
    const response = await fetch(`/customers/${customerId}/contacts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    });

    return await response.json();
  } catch (error) {
    console.error(`Error occurred fetching customer contact for customer Id: ${customerId}`, error);
  }
}
