import { handleInputRowRemove } from "../../common";

const handleRemoveCourseAircraftRowButtons = () => {
  document.querySelectorAll('.remove-row-button.remove-fts-aircraft').forEach((removeButton) => {
    removeButton.addEventListener("click", () => {
      handleInputRowRemove(removeButton, removeButton.closest('.fts-aircraft'))
    });
  });
};
document.addEventListener("turbolinks:load", () => {
  handleRemoveCourseAircraftRowButtons()
});
