const handleMenuSlider = () => {
  const menuSlider = document.querySelector(".topbar-menu .nav-link[data-fts-toggle='sidebar-menu']");
  const navEl = document.querySelector("nav.main");
  const bodyContainer = document.querySelector(".body-container");

  menuSlider.addEventListener("click", () => {
    navEl.classList.toggle("sidebar-closed");
  });

  bodyContainer.addEventListener("click", () => {
    navEl.classList.add("sidebar-closed");
  });
}

document.addEventListener("turbolinks:load", () => {
  handleMenuSlider();
});
