const handleSidebarLinkHoverState = () => {
  const sidebar = document.querySelector("nav > .sidebar-menu");

  sidebar.querySelectorAll(".nav-link").forEach(navLink => {
    navLink.addEventListener("mouseenter", () => {
      navLink.classList.add("active");
    });

    navLink.addEventListener("mouseleave", () => {
      navLink.classList.remove("active");
    })

    navLink.addEventListener("click", () => {
      document.querySelector("nav.main").classList.add("sidebar-closed");
    })
  });
}

document.addEventListener("turbolinks:load", () => {
  handleSidebarLinkHoverState();
});
