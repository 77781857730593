import { handleInputRowRemove } from "../../common";

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.payment-container .remove-row-button').forEach(removeRowButton => {
    removeRowButton.addEventListener("click", ({ target }) => {
      const elementToRemove = removeRowButton.dataset.afRemoveTarget ? removeRowButton.closest(removeRowButton.dataset.afRemoveTarget) : content;
      handleInputRowRemove(target, elementToRemove);
    });
  });
});
