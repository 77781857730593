import { createPopper } from "@popperjs/core";
import { Datepicker } from "vanillajs-datepicker";
import {BS_HIDE_CLASS} from "./constants";

export const currentLocale = () => document.body.dataset.locale;

export const configureAddFieldButtons = () => {
  const addFieldElements = document.querySelectorAll(".add-fields");

  addFieldElements.forEach(element => {
    if (!element.dataset.afTarget) throw new Error(".add-field button found but `data-af-target` must be specified to know where to place content.")
    const targetContainer = document.querySelector(element.dataset.afTarget);

    if (!targetContainer) throw new Error("Node specified in `data-af-target` could not be found.");

    element.addEventListener("click", addFieldButtonClickListener);
  });
}

export const addFieldButtonClickListener = ({ target }) => {
  const targetContainer = document.querySelector(target.dataset.afTarget);

  const data = targetContainer.getElementsByTagName("template")[0]?.content?.cloneNode(true);
  if (!data) throw new Error("`.add-field` button found but could not find a template node within target container.")

  const objectId = Math.floor(Math.random() * 1000000000);
  const content = data.firstElementChild

  targetContainer.appendChild(content);

  targetContainer.lastChild.outerHTML = targetContainer.lastChild.outerHTML.replace(/TEMPLATE_RECORD/g, objectId);

  targetContainer.lastChild.querySelectorAll('.remove-row-button').forEach(removeRowButton => {
    removeRowButton.addEventListener("click", ({target}) => {
      const elementToRemove = removeRowButton.dataset.afRemoveTarget ? removeRowButton.closest(removeRowButton.dataset.afRemoveTarget) : content;
      handleInputRowRemove(target, elementToRemove);
    });
  });
}

export const handleInputRowRemove = (removeButton, elementToRemove) => {
  if (removeButton.classList.contains(BS_HIDE_CLASS)) return;
  const destroyField = elementToRemove.querySelector("input[type=hidden][name$='[_destroy]']");
  // hide the container if true. see if you can remove newly created containers and only toggle destroy flags for existing records
  if (destroyField) {
    destroyField.value = "1";
    elementToRemove.classList.add(BS_HIDE_CLASS);
  } else {
    elementToRemove.remove();
  }
}

export const renderLoader = (target, type = "secondary", mini = false) => {
  if (!target.classList.contains("position-relative")) target.classList.add("position-relative");
  const overlay = document.createElement("div");
  overlay.classList.add("loading-overlay");
  target.prepend(overlay);
  overlay.innerHTML = `
  <div class="spinner-border${mini ? " spinner-border-sm" : ""} text-${type} position-absolute top-50 start-50 translate-middle" role="status">
    <span class="${BS_HIDE_CLASS}">Loading...</span>
  </div>
  `;
}

export const removeLoader = (target) => {
  target.querySelector(".loading-overlay").remove();
}

export const customPopover = (container) => {
  let { placement, target } = container.dataset;
  if (!target) throw new Error("No target provided!");

  placement = placement || 'right';
  const popover = document.querySelector(target);

  const popoverWrapper = document.createElement('div');
  popoverWrapper.classList.add(["popover", "fade", "show"]);
  popoverWrapper.innerHTML = popover;

  document.body.append(popoverWrapper, container);

  createPopper(container, popoverWrapper, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
}

export const addDatePickerToForm = (el) => {
  if (!el) return;
  let language = "en";
  if (currentLocale() == "en") {
    language = "en-CA"
  } else {
    language = currentLocale();
  }

  new Datepicker(el, {
    buttonClass: "btn",
    language,
  });
}

export const initializeSearchFilter = (el) => {
  const target = el.dataset.target;
  const targetCells = el.dataset.targetCells;
  ['change', 'keyup'].forEach((eventType) => {
    el.querySelector('input').addEventListener(eventType, (e) => {
      const filter = e.target.value.toUpperCase();

      document.querySelectorAll(target).forEach((row) => {
        let query = (!!targetCells ? Array.from(row.querySelectorAll(targetCells)).map((cell) => cell.innerText).join(" ") : row.innerText).toUpperCase();

        if (filter.length < 3 || query.indexOf(filter) > -1) {
          row.style.display = '';
        } else {
          row.style.display = 'none';
        }
      });
    });
  });

  el.querySelector('.search-filter-reset').addEventListener("mouseup", (e) => {
    el.querySelector('input').value = '';
    el.querySelector('input').dispatchEvent(new Event('change'));
  });
}