/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import * as Bootstrap from "bootstrap";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import "views";

import "../styles/application.scss";
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/theme.css";
import "jquery-ui/themes/base/datepicker.css";

import {addDatePickerToForm, configureAddFieldButtons, initializeSearchFilter} from "../common";

const handleFormValidation = () => {
  const forms = document.querySelectorAll('.needs-validation')

  if (!forms) return;

  forms.forEach(form => {
    form.addEventListener("submit", (event) => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }

      form.classList.add('was-validated');
    }, false);
  });
}

document.addEventListener("turbolinks:load", () => {
  handleFormValidation();
  configureAddFieldButtons();

  // initialize popovers
  document.querySelectorAll('[data-bs-toggle="popover"]')
    .forEach(popoverTriggerEl => new Bootstrap.Popover(popoverTriggerEl));

  //initialize dropdowns
  document.querySelectorAll("[data-bs-toggle='dropdown']")
    .forEach(dropdownTriggerEl => new Bootstrap.Dropdown(dropdownTriggerEl));

  document.querySelectorAll("[data-bs-toggle='tooltip']")
    .forEach(tooltipTriggerEl => new Bootstrap.Tooltip(tooltipTriggerEl))

  document.querySelectorAll("input.date-field")
    .forEach(dateFieldEl => addDatePickerToForm(dateFieldEl));

  document.querySelectorAll(".search-filter")
    .forEach(searchEl => initializeSearchFilter(searchEl));

  document.querySelectorAll("[data-bs-toggle='html_popover']")
    .forEach((popoverTriggerEl) => new Bootstrap.Popover(popoverTriggerEl, {
        html: true,
        delay: {
          "hide": 750
        },
        fade: false,
      })
    );
});
