const handleNewAddressSelected = () => {
  const radioButtons = document.querySelectorAll('input[name=address_mode]');
  if (!radioButtons && !radioButtons.length) return;

  radioButtons.forEach((radioButton) => {
    radioButton.addEventListener("change", () => {
      const newAddressFormContainer = document.querySelector("#new-address-form-container");
      const newAddressRadioButton = document.querySelector("input#address-new[name=address_mode]");
      if (newAddressRadioButton.checked) {
        newAddressFormContainer.classList.remove("d-none");
      } else {
        newAddressFormContainer.classList.add("d-none");
      }

      const prevRadioButton = document.querySelector("input#address-prev[name=address_mode]");
      const prevFormContainer = document.querySelector("#address-prev-container");
      if (prevRadioButton.checked) {
        prevFormContainer.classList.remove("d-none");
      } else {
        prevFormContainer.classList.add("d-none");
      }
    });
  });
};

document.addEventListener("turbolinks:load", () => {
  handleNewAddressSelected();
});
