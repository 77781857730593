import {currentLocale} from "../../common";

const calculateTotalCharge = () => {
  const expenseChargeEl = document.querySelector("#expense_charge") || 0;
  const expenseHstEl = document.querySelector("#expense_hst") || 0;

  const charge = parseFloat(expenseChargeEl.value) || 0;
  const hst = parseFloat(expenseHstEl.value) || 0;

  const totalCharge = (charge - hst).toFixed(2);
  const currencySelect = document.querySelector("select[name='expense[currency_id]']");
  let currencyText = currencySelect.options[currencySelect.selectedIndex].innerText;

  if (currencyText === 'EURO') {
    currencyText = 'EUR';
  }

  document.querySelector("#total_charge").innerHTML =
    new Intl.NumberFormat(
      currentLocale(),
      {
        style: 'currency',
        currency: currencyText,
        currencyDisplay: 'narrowSymbol'
      }).format(Number(totalCharge));
}

const initializeExpenseCategoryChange = () => {
  const expenseNameEl = document.querySelector("#expense_expense_name");
  const expenseNameOtherEl = document.querySelector("#expense_expense_name_other")
  if(!expenseNameEl || !expenseNameOtherEl) return;
  
  expenseNameEl.addEventListener("change", (event) => {
    const { value } = event.currentTarget;

    if (value === 'other') {
      expenseNameOtherEl.classList.remove('visually-hidden');
    } else {
      expenseNameOtherEl.classList.add('visually-hidden');
    }
  });
};

const initializeCalculateTotal = () => {
  const expenseChargeEl = document.querySelector("#expense_charge");
  const expenseHstEl = document.querySelector("#expense_hst");
  const currencySelect = document.querySelector("select[name='expense[currency_id]']");

  if (!expenseChargeEl || !expenseHstEl) return;

  calculateTotalCharge();
  expenseChargeEl.addEventListener("focusout", calculateTotalCharge);
  expenseHstEl.addEventListener("focusout", calculateTotalCharge);
  currencySelect.addEventListener("focusout", calculateTotalCharge);
};

document.addEventListener("turbolinks:load", () => {
  initializeExpenseCategoryChange();
  initializeCalculateTotal();
});
