const setFileTypeChangeListener = () => {
  const fileTypeSelector = document.getElementById("document_file_type");
  if (!fileTypeSelector) return;

  fileTypeSelector.addEventListener("change", handleFileTypeChange);
};

const handleFileTypeChange = () => {
  const fileTypeSelector = document.getElementById("document_file_type");
  if (!fileTypeSelector) return

  const val = fileTypeSelector.value;

  const fileTypeNameTextField = document.querySelector("#document_file_type_name.file-text")
  if (!fileTypeNameTextField) return;

  const fileTypeNameInvoiceSelect = document.querySelector("#document_file_type_name_select.file-select")

  const hideClassName = "d-none";

  if (val === "other") {
    fileTypeNameInvoiceSelect?.setAttribute("disabled", true);
    fileTypeNameTextField.removeAttribute("disabled");

    fileTypeNameInvoiceSelect?.closest(".field").classList.add(hideClassName);
    fileTypeNameTextField.closest(".field").classList.remove(hideClassName);
  } else if (val === "invoice") {
    fileTypeNameInvoiceSelect.removeAttribute("disabled");
    fileTypeNameTextField.setAttribute("disabled", true);

    fileTypeNameInvoiceSelect.closest(".field").classList.remove(hideClassName);
    fileTypeNameTextField.closest(".field").classList.add(hideClassName);
  } else {
    fileTypeNameInvoiceSelect?.setAttribute("disabled", true);
    fileTypeNameTextField.setAttribute("disabled", true);

    fileTypeNameInvoiceSelect?.closest(".field").classList.add(hideClassName);
    fileTypeNameTextField.closest(".field").classList.add(hideClassName);
  }
};

document.addEventListener("turbolinks:load", () => {
  handleFileTypeChange();
  setFileTypeChangeListener();
});
